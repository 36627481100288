import { Controller } from "stimulus";
import Swal from "sweetalert2";

export default class extends Controller {
  openDialog() {
    Swal.fire({
      title: "シェアする",
      html: this.element.querySelector(".share-buttons").innerHTML,
      showCancelButton: false,
      showConfirmButton: false,
    });
  }
}
